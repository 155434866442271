import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { store } from "./store/index";
import { router } from "./router/index";

loadFonts();

console.log = function(){};
console.warn = function(){};
console.error = function(){};
console.debug = function(){};
console.info = function(){};
console.assert = function(){};


const app = createApp(App);
app.use(vuetify);
app.use(store);
app.use(router);
app.mount('#app');

// TODO Check SystemHealthCheckFlag from Firestore
// TODO Check Intro Alert Message

// TODO Release EnterKey on MessageIn