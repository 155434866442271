<template>
  <v-row justify="space-around">
    <v-card id="order-detail" class="my-3 mx-auto w-75" max-width="1000" elevation="0">

      <v-card-title :class="colors.text00">Details</v-card-title>

      <v-timeline class="text-subtitle-2 overflow-y-auto" direction="horizontal">

        <v-timeline-item :dot-color="timelines.pending.dot" :size="timelines.pending.size">
          <div :class="timelines.pending.text">Pending</div>
        </v-timeline-item>

        <v-timeline-item v-if="orderStatus === 'expired'" :dot-color="timelines.expired.dot" :size="timelines.expired.size">
          <template v-slot:opposite>
            <div :class="timelines.expired.text">Expired</div>
          </template>
        </v-timeline-item>
        <v-timeline-item v-else :dot-color="timelines.inprogress.dot" :size="timelines.inprogress.size">
          <template v-slot:opposite>
            <div :class="timelines.inprogress.text">In Progress</div>
          </template>
        </v-timeline-item>

        <v-timeline-item :dot-color="timelines.complete.dot" :size="timelines.complete.size">
            <div :class="timelines.complete.text">Complete</div>
        </v-timeline-item>

      </v-timeline>

      <!--  Empty Table -->
      <v-row v-if="orderStatus === 'empty'" justify="space-around" class="text-center  mb-1">
        <v-col cols="12" class="ma-0 text-orange-accent-4">
          <v-img src="../assets/satoshibento_empty.png" class="my-3" contain height="64" alt="empty target for natary service in the bitcoin network with censorship resistance"></v-img>
        
          <p>SatoshiBento is empty</p>
          <p>Please new order or search previous order</p>
          </v-col>
          <v-col cols="12" class="ma-0 text-orange-accent-4">
          <p>Also, you can try sample-order of SatoshiBento</p>
        </v-col>
          <qr_code class="mt-5" :size="qrsize" :text="orderInfo.permalinkSampleMessage "></qr_code>
          <v-col cols="12" class="ma-0 pt-0 text-orange-accent-4 ">
            <a :href="orderInfo.permalinkSampleMessage" class="text-body-2 text-orange-accent-3">Permalink for sample order - Message</a>
          </v-col>
          <qr_code class="mt-5" :size="qrsize" :text="orderInfo.permalinkSampleFile"></qr_code>
          <v-col cols="12" class="ma-0 pt-0 text-orange-accent-4 ">
            <a :href="orderInfo.permalinkSampleFile" class="text-body-2 text-orange-accent-3">Permalink for sample order - File</a>
          </v-col>
      </v-row>

      <v-row v-if="orderStatus !== 'empty'" justify="space-around" class="text-center mb-1 mt-3">
        <qr_code :size="qrsize" :text="orderInfo.permalink"></qr_code>
        <v-col cols="12" class="ma-0 pt-0 text-orange-accent-4 text-caption">
          <a :href="orderInfo.permalink" class="text-body-2 text-orange-accent-3">Permalink for this order</a>
        </v-col>
      </v-row>

      <v-table v-if="orderStatus !== 'empty'" :class="colors.text00" density="compact">
        <tbody>
          <tr v-for="item in orderDetails" :key="item.name">
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </v-row>

  <v-row v-if="orderStatus === 'pending'" justify="space-around" class="text-center mt-1">
    <v-col cols="12" class="ma-0 text-orange-accent-4">
      <p>To continue ordering the SatoshiBento,</p>
      <p>please send the minimum price of <b>{{ orderInfo.payValue }} BTC</b> to the address below</p>      
    </v-col>
    <qr_code :size="qrsize" :text="orderInfo.id"></qr_code>
    <v-col cols="12" class="ma-0 text-orange-accent-4">
      <h4> {{ orderInfo.id }} </h4>
      <p class="text-caption">ExpireTime: {{ orderInfo.expireTime }} </p>
    </v-col>
    <v-table :class="colors.text00" density="compact">
      <tbody class="text-caption">
        <tr v-for="item in orderInfo.pays" :key="item.name">
          <td class="text-caption">{{ item.Value }} BTC</td>
          <td class="text-caption">{{ item.TxID }}</td>
        </tr>
      </tbody>
    </v-table>
  </v-row>


  <v-row v-if="orderStatus === 'expired'" justify="space-around" class="text-center mt-1">
    <v-col cols="12" class="ma-0 text-orange-accent-4">
      <br>
      <p>If you want to proceed with the order,</p>
      <p>you need to place a new order again.</p>
    </v-col>
  </v-row>



  <v-row v-if="orderStatus === 'inprogress' || orderStatus === 'complete'" justify="space-around"
    class="text-center mt-1">
    <v-col v-if="orderInfo.type === 'file'" cols="12" class="ma-0 text-orange-accent-4 overflow-y-auto">
      <p><b>Your File (CID)</b></p>
      <!-- <p class="">{{ orderInfo.contentFileCID }}</p> -->
      <a :href="ipfsURL(orderInfo.contentFileCID, orderInfo.contentFileIPFS)" class="text-body-2 text-orange-accent-3" target="_blank">{{
          orderInfo.contentFileCID
      }}</a>
      <p class="text-caption">Download CID of Your File from IPFS</p>
    </v-col>

    <v-col v-if="orderInfo.type === 'message'" cols="12" class="ma-0 text-orange-accent-4 overflow-y-auto">
      <p><b>Your Message (TxID)</b></p>
      <a :href="btcExplorerURL(orderInfo.contentMessageTxID)" class="text-body-2 text-orange-accent-3" target="_blank">{{ orderInfo.contentMessageTxID }}<p></p></a>
      <p class="text-caption">Check TxIDs on bitcoin-explorer</p>
    </v-col>

    <v-col cols="12" class="ma-0 text-orange-accent-4 overflow-y-auto">
      <p><b>Your Proof (TxID)</b></p>
      <a v-for="item in orderInfo.pays" :key="item.name" :href="btcExplorerURL(item.TxID)" class="text-body-2 text-orange-accent-3" target="_blank">{{ item.TxID }}<p></p></a>
      <p class="text-caption">Check TxIDs on bitcoin-explorer</p>
    </v-col>

    <v-col v-if="orderStatus === 'complete'" cols="12" class="ma-0 text-orange-accent-4 overflow-y-auto">
      <p><b>SatoshiBento Receipt (CID)</b></p>
      <!-- <p class="">{{ orderInfo.receiptCID }}</p> -->
      <a :href="ipfsURL(orderInfo.receiptCID, orderInfo.receiptIPFS)" class="text-body-2 text-orange-accent-3" target="_blank">{{
          orderInfo.receiptCID
      }}</a>
      <p class="text-caption">Download CID of SatoshiBento Receipt from IPFS</p>
    </v-col>
  </v-row>


</template>

<script>
import { computed } from "vue";
import { useStore } from 'vuex';
import qr_code from 'vue-qrcode-component'

export default {

  setup() {
    const store = useStore();
    const states = computed(() => { return store.state });
    return { store, states };
  },

  components: {
    qr_code,
  },

  computed: {

    qrsize() {
      return 100
    },

    timelines() {
      let settings = {
        "empty": {
          "dot": "orange-lighten-3",
          "text": "text-orange-lighten-3",
          "size": "x-small",
        },
        "expired": {
          "dot": "orange-lighten-3",
          "text": "text-orange-lighten-3",
          "size": "x-small",
        },
        "pending": {
          "dot": "orange-lighten-3",
          "text": "text-orange-lighten-3",
          "size": "x-small",
        },
        "inprogress": {
          "dot": "orange-lighten-3",
          "text": "text-orange-lighten-3",
          "size": "x-small",
        },
        "complete": {
          "dot": "orange-lighten-3",
          "text": "text-orange-lighten-3",
          "size": "x-small",
        },
      };

      settings[this.orderStatus].dot = "orange-accent-4";
      settings[this.orderStatus].text = "text-orange-accent-4";
      settings[this.orderStatus].size = "large";

      return settings
    },

    orderStatus() {
      let tStatus = this.states.OrderDetailInfo.Status;
      if (tStatus === undefined || tStatus === "") {
        tStatus = "empty";
      }
      console.log("== tStatus:", tStatus);
      return tStatus // "empty" , "pending" , "expired",  "inprogress" , "complete"
    },

    orderStatusDetail() {

      const tStatus = this.orderStatus;

      let tStatusDetail = "";
      if (tStatus === "empty") {
        tStatusDetail = "Empty";
      } else if (tStatus === "pending") {
        tStatusDetail = "Pending for payment in full";
      } else if (tStatus === "expired") {
        tStatusDetail = "Expired after " + this.orderInfo.expireTime;
      } else if (tStatus === "inprogress") {
        tStatusDetail = "In progress in the bitcoin network";
      } else if (tStatus === "complete") {
        tStatusDetail = "Complete, please check your receipt of the SatoshiBento";
      }
      
      return tStatusDetail
    },

    orderDetails() {
      if (this.orderStatus === "empty") {
        return [
          { key: 'OrderID', value: "- (address of bitcoin-network)" },
          { key: 'OrderDigest', value: "- (SHA256 of 'FILE' or 'MESSAGE')" },
          { key: 'OrderTime', value: "-" },
          { key: 'OrderType', value: "- ('FILE' or 'MESSAGE')" },
          { key: 'OrderSize', value: "0 bytes" },
          { key: 'Status', value: this.orderStatusDetail },
        ]
      } else {
        return [
          { key: 'OrderID', value: this.orderInfo.id },
          { key: 'OrderDigest', value: this.orderInfo.digest },
          { key: 'OrderTime', value: this.orderInfo.time },
          { key: 'OrderType', value: this.upperCaseOrderType(this.orderInfo.type) },
          { key: 'OrderSize', value: this.addNumberComma(this.orderInfo.size) + " bytes" },
          { key: 'Status', value: this.orderStatusDetail },
        ]
      }
    },

    orderInfo() {

      let orderDetail = {};
      orderDetail.status = this.states.OrderDetailInfo.Status;
      orderDetail.permalink = "https://satoshibento.io/order/"+this.states.OrderDetailInfo.OrderID;
      orderDetail.digest = this.states.OrderDetailInfo.ContentSHA256;
      orderDetail.id = this.states.OrderDetailInfo.OrderID;
      orderDetail.time = this.timestampToLocalDateTime(this.states.OrderDetailInfo.OrderTS); // "1981-02-22T09:00:00+09:00";
      orderDetail.type = this.states.OrderDetailInfo.ContentType;
      orderDetail.size = this.states.OrderDetailInfo.ContentSize; // "123,567,175 bytes";
      orderDetail.payValue = this.states.OrderDetailInfo.OrderPayValue;

      orderDetail.statusDescription = this.states.OrderDetailInfo.Status;

      orderDetail.expireTime = this.timestampToLocalDateTime(this.states.OrderDetailInfo.PendingTS + 1000 * 60 * 60 * 24 * 10 ); // 10-days;

      orderDetail.contentFileCID = this.states.OrderDetailInfo.ContentFileCID;
      orderDetail.contentFileIPFS = this.states.OrderDetailInfo.ContentFileIPFS;
      

      orderDetail.contentMessageTxID = this.states.OrderDetailInfo.ContentMessageOpReturnTxID;

      orderDetail.pays = this.states.OrderDetailInfo.Pays;

      orderDetail.receiptCID = this.states.OrderDetailInfo.ReceiptPdfCID;
      orderDetail.receiptIPFS = this.states.OrderDetailInfo.ReceiptPdfIPFS;

      orderDetail.permalinkSampleFile = "/order/17pg4gqjGD2943svomZgfBb8jrRDvGyFiH"
      orderDetail.permalinkSampleMessage = "/order/18YDzc7DyundQmgCc51ddjEpKxhvvZZtAw"

      return orderDetail;
    },
  },

  data: () => ({

    colors: {
      text00: "text-orange-accent-4",
      text01: "text-orange-lighten-3",
    },
  }),

  methods: {


    btcExplorerURL(txID){
      const baseURL = process.env.VUE_APP_SatoshiBentoShopBitcoinExplorerURL;
      return baseURL + "/" + txID;
    },

    ipfsURL(cID, ipfsGW){

      const baseUrlLocal = process.env.VUE_APP_SatoshiBentoShopIpfsURL;
      const baseUrlRemote = process.env.VUE_APP_SatoshiBentoShopIpfsRemoteURL;

      let baseURL = baseUrlLocal;

      if (ipfsGW !== undefined && ipfsGW ==="remote"){
        baseURL = baseUrlRemote;
      }

      return baseURL + "/" + cID;
    },

    upperCaseOrderType(orderType) {
      if (orderType === undefined) {
        return "";
      }
      return orderType.toUpperCase();
    },

    parsingReceiptOpReturnReadable(readableStr) {

      if (readableStr === undefined || readableStr === "") {
        return "";
      }
      // SatBtRecptQmNQpJ9kEyYJwJ3XHF3zTZS8Hn4cSpZsLwApCk9zYdsxT6 tb1qln29hcxs0csrjpf7";
      const tSplit = readableStr.split(" ");
      if (tSplit.length !== 3) {
        return "";
      }
      return tSplit[1];
    },

    addNumberComma(num) {
      var regexp = /\B(?=(\d{3})+(?!\d))/g;
      if (num === undefined) {
        num = 0;
      }
      return num.toString().replace(regexp, ',');
    },

    cutLengthTxID(txid, len) {
      return txid.substring(0, len) + "...";
    },

    timestampToLocalDateTime(ts) {
      var d1 = new Date(ts);
      return d1;
    },

    onClick() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.loaded = true;
      }, 2000);
    },
  },
};
</script>