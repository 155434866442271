<template>
    <sb_satoshipen_for_user />
</template> 

<script>
// import sb_search from "../components/SB_Search.vue";
import sb_satoshipen_for_user from "../components/SB_SatoshiPen_For_User.vue";

export default {
    name: "SatoshiPen_For_User",
    components: {
        sb_satoshipen_for_user,
    },
    setup() {},
};
</script>
