import { createStore } from "vuex";

export const store = createStore({
  state () {
    return {
      OrderDetailInfo : {},
      OrderID: ""
    }
  },

  mutations: {
    setOrderDetailInfo (state, detailInfo) {
      state.OrderDetailInfo = detailInfo;
    },
    setOrderID(state, orderID){
      state.OrderID = orderID;
    },
  },

})
