<template>
  <v-container>
    <v-row class="text-center text-orange-accent-4">
      <v-col cols="12">
        <v-img src="../assets/satoshibento_logo.png" class="my-3" contain height="200" alt="Notary service for your file or message in the bitcoin network with censorship resistance like web3 or nft" />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          SatoshiBento.io
        </h1>
        <!-- <h3 class="display-2 font-weight-bold mb-3">
          Beta
        </h3> -->
        <p>We are all Satoshi</p>
        <p>Trust the bitcoin network</p>
        <p class="subheading font-weight-regular">
          <br>
          <a href="https://github.com/ideaJOO/satoshibento" target="_blank">Github</a> / 
          <a href="https://github.com/ideaJOO/satoshibento/wiki" target="_blank">Document</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data: () => ({}),
}
</script>
