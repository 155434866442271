<template>
    <sb_order />
    <sb_search />
    <sb_details />
</template>
<script>
import sb_order from "../components/SB_Order.vue";
import sb_search from "../components/SB_Search.vue";
import sb_details from "../components/SB_Details.vue";

export default {
    name: "SatoshiBento_Home",
    components: {
        sb_order,
        sb_search,
        sb_details,
    }, 

    data: () => ({ 
        //
    }),
};
</script>
