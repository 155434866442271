<template>
    <v-footer class="bg-green-lighten-4 mt-10">
        <v-row justify="center text-orange-accent-4" no-gutters>
            <v-btn v-for="item in items" :key="item.Key" color="text-orange-accent-4" variant="text" class="mx-2" rounded="xl" :href="item.Link">
                {{ item.Key }}
            </v-btn>
            <v-col class="text-center mt-4" cols="12">
                {{ new Date().getFullYear() }} — <strong>SatoshiBento.io</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: 'SBSFooter',
    data: () => ({
        items: [{
            "Key": "Home",
            "Link": "/"
        },
        {
            "Key": "GitHub",
            "Link": "https://github.com/ideaJOO/satoshibento"
        },
        {
            "Key": "Document",
            "Link": "https://github.com/ideaJOO/satoshibento/wiki"
        },
        {
            "Key": "Email",
            "Link": "mailto:nerd@satoshibento.io"
        }],
    }),
}
</script>