<template>
  <v-row justify="space-around">
    
      <v-card id="order-search" class="my-3 mx-auto w-75" max-width="1000" elevation="0">
        <v-card-title class="text-orange-accent-4">Search</v-card-title>
        <v-card-text>
          <v-text-field v-model="searchText" :disabled="tDialog" class="text-orange-accent-4" flat variant="outlined"
            label="Search OrderID or Digest_SHA256" append-inner-icon="mdi-magnify" single-line hide-details 
            @keydown.enter.prevent="onClick" @click:append-inner="onClick">
          </v-text-field>
          <v-progress-linear :active="tProgress" rounded indeterminate color="yellow-darken-2"></v-progress-linear>
          <v-dialog v-model="tDialog" max-width="600">
            <v-card>
              <v-toolbar color="orange-accent-2" class="text-black" title="No results found :("></v-toolbar>
              <v-card-text class="text-orange-accent-4">
                <p class="text-subtitle-1 font-weight-bold mb-3"> Please check OrderID or Digest </p>
                <p class="text-subtitle-2 text-left">
                  e.g.&nbsp;&nbsp;&nbsp;&nbsp;{{ SampleOrderID }}</p>
                <v-divider />
                <p class="text-subtitle-2 text-left">
                  e.g.&nbsp;&nbsp;&nbsp;&nbsp;{{ SampleDigest }}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn color="orange-accent-4" block @click="removeDialog">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
  </v-row>
</template>

<script>

// TODO onClick() 이벤트 발생시, 모바일일경우 키보드가 숨기어져야 함
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_SatoshiBentoShopApiKey,
  authDomain: process.env.VUE_APP_SatoshiBentoShopAuthDomain,
  databaseURL: process.env.VUE_APP_processSatoshiBentoShopDatabaseURL,
  projectId: process.env.VUE_APP_SatoshiBentoShopProjectId,
  storageBucket: process.env.VUE_APP_SatoshiBentoShopStorageBucket,
  messagingSenderId: process.env.VUE_APP_SatoshiBentoShopMessagingSenderId,
  appId: process.env.VUE_APP_SatoshiBentoShopAppId,
  measurementId: process.env.VUE_APP_SatoshiBentoShopMeasurementId,
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const fireStoreOrderIntegritys = process.env.VUE_APP_SatoshiBentoFireStoreOrderIntegritys;
const fireStoreOrderDetails = process.env.VUE_APP_SatoshiBentoFireStoreOrderDetails;
const sampleSearchOrderID = process.env.VUE_APP_SatoshiBentoShopSampleSearchOrderID;
const sampleSearchDigest = process.env.VUE_APP_SatoshiBentoShopSampleSearchDigest;

export default {
  setup() {
    const store = useStore();
    const storeStateOrderID = computed(() => {
      return store.state.OrderID;
    });
    const storeStatOrderDetailInfo = computed(() => {
      return store.state.OrderDetailInfo;
    });

    const progress = ref(false);
    const tProgress = computed(() => {
      return progress.value;
    });


    const dialog = ref(false);
    const tDialog = computed(() => {
      return dialog.value;
    });
    function removeDialog() {
      dialog.value = false;
    }
    function showDialog() {
      dialog.value = true;
    }

    function checkSearchText() {
      const regExp = /^[A-Za-z0-9]*$/.test(searchText.value);
      if (regExp) {
        if (searchText.value.length === 64) {
          console.log("SEARCH", "digest")
          return "digest";
        } else if (searchText.value.length > 25 && searchText.value.length < 50) {
          console.log("SEARCH", "orderid")
          return "orderid";
        }
      }
      console.log("SEARCH", "wrong")
      return "wrong"; // "digest" / "orderid" / "wrong"
    }

    function onClick() {
      if (searchText.value === "") {
        return;
      }
      const searchType = checkSearchText();
      switch (searchType) {
        case "wrong": {
          resetDetailInfos();
          showDialog();
          break;
        }
        case "digest": {
          digestInfo();
          break;
        }
        case "orderid": {
          detailInfos();
          break;
        } 
      }
    }

    const searchText = ref(storeStateOrderID.value);
    const searchResult = ref({});
    const resetDetailInfos = () => {
      searchText.value = "";
      searchResult.value.Status = "";
      searchResult.value.OrderID = "";
      searchResult.value.OrderTS = 0;
      searchResult.value.OrderPayValue = 0.0;
      searchResult.value.PendingTS = 0;
      searchResult.value.ContentSHA256 = "";
      searchResult.value.ContentSize = 0;
      searchResult.value.ContentType = "";
      searchResult.value.ContentMessage = "";
      searchResult.value.Pays = [];
      searchResult.value.ApprovalTS = 0;
      searchResult.value.ContentMessageOpReturnTxID = "";
      searchResult.value.ContentFileCID = "";
      searchResult.value.ContentFileIPFS = "";
      searchResult.value.IntegrityOpReturnReadable = "";
      searchResult.value.IntegrityOpReturnHex = "";
      searchResult.value.IntegrityOpReturnTxID = "";
      searchResult.value.ReceiptOpReturnReadable = "";
      searchResult.value.ReceiptOpReturnHex = "";
      searchResult.value.ReceiptOpReturnTxID = "";
      searchResult.value.ReceiptPdfCID = "";
      searchResult.value.ReceiptPdfIPFS = "";
      searchResult.value.ReceiptJsonCID = "";
      searchResult.value.ReceiptJsonIPFS = "";
      searchResult.value.SucceededTS = 0;
      searchResult.value.Loading = false;
      store.commit("setOrderDetailInfo", searchResult.value);
    };
    
    const digestInfo = async () => {
      progress.value = true;
      await sleep(700);
      const docRef0 = doc(db, fireStoreOrderIntegritys, searchText.value);
      const docSnap0 = await getDoc(docRef0);
      if (!docSnap0.exists()) {
        resetDetailInfos();
        showDialog();
      }
      const docData0 = docSnap0.data();
      searchText.value = docData0.OrderID;

      const docRef = doc(db, fireStoreOrderDetails, searchText.value);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();

        if (docData.OrderStatus === "Pending") {
          searchResult.value.Status = "pending";
        } else if (docData.OrderStatus === "Expired") {
          searchResult.value.Status = "expired";
        } else if (docData.OrderStatus === "Approval") {
          searchResult.value.Status = "inprogress";
        } else if (docData.OrderStatus === "Succeeded") {
          searchResult.value.Status = "complete";
        } else {
          searchResult.value.Status = "empty";
        }

        searchResult.value.OrderID = docData.OrderID;
        searchResult.value.OrderTS = docData.OrderTS;
        searchResult.value.OrderPayValue = docData.OrderPayValue;
        searchResult.value.PendingTS = docData.PendingTS;
        searchResult.value.ContentSHA256 = docData.ContentSHA256;
        searchResult.value.ContentSize = docData.ContentSize;
        searchResult.value.ContentType = docData.ContentType;
        searchResult.value.ContentMessage = docData.ContentMessage;
        searchResult.value.Pays = docData.Pays;
        searchResult.value.ApprovalTS = docData.ApprovalTS;
        searchResult.value.ContentMessageOpReturnTxID =
          docData.ContentMessageOpReturnTxID;
        searchResult.value.ContentFileCID = docData.ContentFileCID;
        searchResult.value.ContentFileIPFS = docData.ContentFileIPFS;
        searchResult.value.IntegrityOpReturnReadable =
          docData.IntegrityOpReturnReadable;
        searchResult.value.IntegrityOpReturnHex = docData.IntegrityOpReturnHex;
        searchResult.value.IntegrityOpReturnTxID =
          docData.IntegrityOpReturnTxID;
        searchResult.value.ReceiptOpReturnReadable =
          docData.ReceiptOpReturnReadable;
        searchResult.value.ReceiptOpReturnHex = docData.ReceiptOpReturnHex;
        searchResult.value.ReceiptOpReturnTxID = docData.ReceiptOpReturnTxID;
        searchResult.value.ReceiptJsonCID = docData.ReceiptJsonCID;
        searchResult.value.ReceiptJsonIPFS = docData.ReceiptJsonIPFS;
        searchResult.value.ReceiptPdfCID = docData.ReceiptPdfCID;
        searchResult.value.ReceiptPdfIPFS = docData.ReceiptPdfIPFS;
        searchResult.value.SucceededTS = docData.SucceededTS;
        searchResult.value.Loading = false;
        store.commit("setOrderDetailInfo", searchResult.value);
      } else {
        resetDetailInfos();
        showDialog();
      }
      progress.value = false;
    };


    const sleep = async (ms) => {
      return new Promise(
        (resolve) =>
          setTimeout(
            () => resolve(),
            ms
          )
        );
      };
      
    
    const detailInfos = async () => {
      progress.value = true;
      console.log("[SEARCH][detailInfos]", searchText.value);
      console.log("[SEARCH][detailInfos]", fireStoreOrderDetails);
      console.log("[SEARCH][detailInfos]", searchText.value);
      await sleep(700);
      
      const docRef = doc(db, fireStoreOrderDetails, searchText.value);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("[SEARCH][detailInfos]", "docSnap.exists()");
        const docData = docSnap.data();

        if (docData.OrderStatus === "Pending") {
          searchResult.value.Status = "pending";
        } else if (docData.OrderStatus === "Expired") {
          searchResult.value.Status = "expired";
        } else if (docData.OrderStatus === "Approval") {
          searchResult.value.Status = "inprogress";
        } else if (docData.OrderStatus === "Succeeded") {
          searchResult.value.Status = "complete";
        } else {
          searchResult.value.Status = "empty";
        }

        searchResult.value.OrderID = docData.OrderID;
        searchResult.value.OrderTS = docData.OrderTS;
        searchResult.value.OrderPayValue = docData.OrderPayValue;
        searchResult.value.PendingTS = docData.PendingTS;
        searchResult.value.ContentSHA256 = docData.ContentSHA256;
        searchResult.value.ContentSize = docData.ContentSize;
        searchResult.value.ContentType = docData.ContentType;
        searchResult.value.ContentMessage = docData.ContentMessage;
        searchResult.value.Pays = docData.Pays;
        searchResult.value.ApprovalTS = docData.ApprovalTS;
        searchResult.value.ContentMessageOpReturnTxID =
          docData.ContentMessageOpReturnTxID;
        searchResult.value.ContentFileCID = docData.ContentFileCID;
        searchResult.value.ContentFileIPFS = docData.ContentFileIPFS;
        searchResult.value.IntegrityOpReturnReadable =
          docData.IntegrityOpReturnReadable;
        searchResult.value.IntegrityOpReturnHex = docData.IntegrityOpReturnHex;
        searchResult.value.IntegrityOpReturnTxID =
          docData.IntegrityOpReturnTxID;
        searchResult.value.ReceiptOpReturnReadable =
          docData.ReceiptOpReturnReadable;
        searchResult.value.ReceiptOpReturnHex = docData.ReceiptOpReturnHex;
        searchResult.value.ReceiptOpReturnTxID = docData.ReceiptOpReturnTxID;
        searchResult.value.ReceiptJsonCID = docData.ReceiptJsonCID;
        searchResult.value.ReceiptJsonIPFS = docData.ReceiptJsonIPFS;
        searchResult.value.ReceiptPdfCID = docData.ReceiptPdfCID;
        searchResult.value.ReceiptPdfIPFS = docData.ReceiptPdfIPFS;
        searchResult.value.SucceededTS = docData.SucceededTS;
        searchResult.value.Loading = false;
        store.commit("setOrderDetailInfo", searchResult.value);
      } else {
        console.log("[SEARCH][detailInfos]", "not docSnap.exists()");
        resetDetailInfos();
        showDialog();
      }
      progress.value = false;
    };

    onMounted(() => {
      console.log("== storeStateOrderID:", storeStateOrderID.value);
      searchText.value = storeStateOrderID.value;
      onClick();
    });

    return {
      store,
      storeStateOrderID,
      storeStatOrderDetailInfo,
      searchText,
      digestInfo,
      detailInfos,
      resetDetailInfos,
      tDialog,
      onClick,
      removeDialog,
      showDialog,
      tProgress,
    };
  },

  data: () => ({
    SampleOrderID : sampleSearchOrderID,
    SampleDigest : sampleSearchDigest,
  }),

  methods: {
  },
};
</script>
