<template>
  <v-row justify="space-around">
    <v-card id="order-detail" class="my-3 mx-auto w-75" max-width="1000" elevation="0">

      <v-card-text v-html="contents"></v-card-text>
    </v-card>
  </v-row>


</template>

<script>
export default {

  setup() {
    
    return ;
  },
  

  data: () => ({

    contents: `
    Satoshi Book <br><br> 
    *English*  <br>
1. Purpose of processing personal information <br>
&nbsp;(1) 'SatoshiBook' processes personal information for the following purposes and does not use it for any other purpose than the following: <br>
&emsp;- Device ID and Advertising Data for Banner/Interstitial Ad.<br>
   <br>
2. Processing and retention period of personal information <br>
&nbsp;(1) 'SatoshiBook' collects and processes personal information within the retention and use period of personal information agreed upon by the information subject at the time of collection or within the retention and use period of personal information required by law. <br>
&nbsp;(2) The specific processing and retention period of personal information are as follows: <br>
&emsp;- For example, customer registration and management: until the service. usage contract or membership is terminated, except when there are remaining debts or obligations, in which case until the settlement of such debts or obligations. <br>
&emsp;- For example, records of contracts, cancellations of subscription, payment of fees, supply of goods, etc. in e-commerce: 5 years. <br>
<br>
3. Rights and obligations of the information subject and how to exercise them <br>
&nbsp;(1) As an information subject, the user may exercise the following rights regarding the protection of personal information to 'SatoshiBook' at any time: <br>
&emsp;- Request for access to personal information. <br>
&emsp;- Request for correction if there is an error. <br>
&emsp;- Request for deletion. <br>
&emsp;- Request for processing to be stopped. <br>
   <br>
4. Items of personal information being processed <br>
&nbsp;(1) 'SatoshiBook' processes the following personal information items: <br>
&emsp;- Device ID, Advertising Data<br>
   <br>
5. Destruction of personal information <br>
&nbsp;(1) 'SatoshiBook' promptly destroys the relevant personal information when the purpose of processing personal information is achieved. The procedures, deadlines, and methods of destruction are as follows: <br>
&emsp;- Procedures: Information entered by the user is moved to a separate DB (in the case of paper, a separate document) after the purpose is achieved, and it is stored for a certain period according to internal policies and other relevant laws and regulations, or immediately destroyed. Personal information moved to a DB is not used for any other purpose unless required by law. <br>
&emsp;- Deadline: Personal information of the user is destroyed within 30 days from the end of the retention period, or within 30 days from the date when it is determined that the processing of personal information is no longer necessary, such as when the purpose of processing personal information has been achieved, or the service is terminated, or the business is closed. <br>
   <br> 
6. Measures to ensure the safety of personal information <br>
&nbsp;(1) 'SatoshiBook' is taking the following technical/managerial and physical measures to ensure safety: <br>
&nbsp;(2) Minimization and education of employees handling personal information <br>
&emsp;- We have implemented measures to manage personal information by designating employees who handle personal information and limiting them to only those who are responsible for it. <br>
   &nbsp;(3) Technical measures against hacking, etc. <br>
   &emsp;- 'SatoshiBook' installs and regularly updates and checks security programs to prevent personal information from being leaked or damaged by hacking or computer viruses. We also install systems in areas where access is controlled from the outside and monitor and block them technologically and physically. <br>
   &nbsp;(4) Restriction of access to personal information <br>
   &emsp;- We take necessary measures to control access to personal information by granting, changing, and revoking access rights to the database system that processes personal information, and we control unauthorized access from the outside using an intrusion prevention system. <br>
   <br>
7. Designation of Personal Information Protection Manager <br>
&nbsp;(1) 'SatoshiBook' is responsible for all tasks related to personal information processing and has designated a personal information protection manager to handle complaints and remedies related to personal information processing as follows: <br>
&emsp;- Contact: nerd@satoshibento.io <br>
   &nbsp;(2) Information subjects may contact the personal information protection manager or the relevant department to inquire about all matters related to personal information protection, including complaints and remedies, that have arisen while using the services (or business) of 'SatoshiBook'. 'SatoshiBook' will respond and process inquiries from information subjects without delay. <br>
 <br>
8. Changes to the personal information processing policy <br>
&nbsp;(1) This personal information processing policy will be effective from November 1, 2017, and in the event of additions, deletions, or corrections due to legal or policy changes, we will notify them through the notice board 7 days before the changes take effect. <br>
 <br> <br>
*한국어* <br>
1. 개인정보의 처리 목적 <br>
&nbsp;(1) 'SatoshiBook'은 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다. <br>
&emsp;- 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 금액 결제 <br>
   <br>
2. 개인정보의 처리 및 보유 기간 <br>
&nbsp;(1) 'SatoshiBook'은 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간 또는 법령에 따른 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다. <br>
&nbsp;(2) 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다. <br>
&emsp;- 아래 예시를 참고하여 개인정보 처리업무와 개인정보 처리업무에 대한 보유기간 및 관련 법령, 근거 등을 기재합니다. <br>
&emsp;- 예. 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권․채무관계. 잔존시에는 해당 채권․채무관계 정산시까지 <br>
&emsp;- 예. 전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년 <br>
   <br>
3. 정보주체의 권리,의무 및 그 행사방법 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다. <br>
&nbsp;(1) 정보주체는 'SatoshiBook'에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. <br>
&emsp;- 개인정보 열람요구 <br>
&emsp;- 오류 등이 있을 경우 정정 요구 <br>
&emsp;- 삭제요구 <br>
&emsp;- 처리정지 요구 <br>
   <br>
4. 처리하는 개인정보의 항목 작성 <br>
&nbsp;(1) 'SatoshiBook' 다음의 개인정보 항목을 처리하고 있습니다. <br>
&emsp;- 이메일, 결제 기록, 비트코인 네트워크에 저장할 메세지 <br>
   <br>
5. 개인정보의 파기 <br>
&nbsp;(1) 'SatoshiBook'은 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다. <br>
&emsp;- 파기절차이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류). 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로. 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. <br>
&emsp;- 파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터. 30일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가. 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 30일 이내에 그. 개인정보를 파기합니다. <br>
   <br>
6. 개인정보의 안전성 확보 조치 <br>
&nbsp;(1) 'SatoshiBook'은 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고. 있습니다. <br>
&nbsp;(2) 개인정보 취급 직원의 최소화 및 교육 <br>
&emsp;- 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는. 대책을 시행하고 있습니다. <br>
   &nbsp;(3) 해킹 등에 대비한 기술적 대책 <br>
   &emsp;- 'SatoshiBook'은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여. 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을. 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. <br>
   &nbsp;(4) 개인정보에 대한 접근 제한 <br>
   &emsp;- 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에. 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다. <br>
   <br>
7. 개인정보 보호책임자 작성 <br>
&nbsp;(1) 'SatoshiBook'은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br>
&emsp;- 연락처: nerd@satoshibento.io <br>
   &nbsp;(2) 정보주체께서는 'SatoshiBook'의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 'SatoshiBook'은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다. <br>
 <br>
8. 개인정보 처리방침 변경 <br>
&nbsp;(1) 이 개인정보처리방침은 2017년 11월 1일부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다. <br>
    `,
  }),
};
</script>