<template>
  <v-row justify="space-around">
    <v-card class="my-3 mx-auto w-75" max-width="1000" elevation="0">
      <v-card-title :class="colors.text00">Order</v-card-title>
      <v-row>
        <v-col :cols="colsCardFile">
          <v-card @click="onClickCardFile" class="mx-auto text-center pt-3 justify-center" color="#F1F8E9" height="253"
            elevation="0" variant="flat">
            <div v-if="colsCardFile === 6">
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-img src="../assets/satoshibento_file.png" class="my-1" contain height="50" alt="Notary your file in the bitcoin network and store in the ipfs like nft" />
              <v-card-subtitle :class="colors.text00">SatoshiBento made of</v-card-subtitle>
              <v-card-subtitle :class="colors.text00"><b>Your File</b></v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle :class="colors.text00">Store/Certificate your file</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle :class="colors.text00">max-size: {{ limitFileSizeMB }} MB</v-card-subtitle>
              <v-card-subtitle :class="colors.text00">{{ orderPayValueFile }} BTC</v-card-subtitle>
            </div>
            <div v-else-if="colsCardFile === 2">
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-img src="../assets/satoshibento_file.png" class="my-1" contain height="30" alt="Notary your file in the bitcoin network and store in the ipfs like nft"/>
            </div>
            <div v-else>
              <v-img src="../assets/satoshibento_file.png" class="my-1" contain height="30" alt="Notary your file in the bitcoin network and store in the ipfs like nft"/>
              <div v-if="orderDigest === ''">
                <v-card-subtitle :class="colors.text00">SatoshiBento made of <b>Your File</b></v-card-subtitle>
                <v-card class="mx-auto mt-2 text-center justify-center w-75" color="white" height="120" elevation="1"
                  variant="flat" @click="onClickButtonFileSelect">
                  <v-card-title :class="colors.text00">Select a File</v-card-title>
                  <v-card-subtitle :class="colors.text00">Store/Certificate your file</v-card-subtitle>
                  <v-card-subtitle :class="colors.text00">with the original on the IPFS</v-card-subtitle>
                  <v-card-subtitle :class="colors.text00">and the digest on the bitcoin network</v-card-subtitle>
                </v-card>
                <v-btn class="mt-1 mx-1" :disabled="fileSubmitBtnDisabled" variant="text" color="green-accent-4"
                  @click="onClickButtonFileSubmit">
                  Submit
                </v-btn>
                <v-btn class="mt-1 mx-1" variant="text" color="indigo-lighten-2" @click="onClickButtonFileCancel">
                  Cancel
                </v-btn>
              </div>
              <div v-else>
                <v-card-subtitle :class="colors.text00">SatoshiBento made of <b>Your File</b></v-card-subtitle>
                <v-card class="mx-auto mt-2 text-center justify-center w-75" color="white" height="120" elevation="1"
                  variant="flat" @click="onClickButtonFileSelect">
                  <v-card-title :class="colors.text00">Selected File</v-card-title>
                  <v-card-subtitle class="text-orange-accent-4 text-start"> <b>Digest</b> : {{ orderDigest }}
                  </v-card-subtitle>
                  <v-card-subtitle class="text-orange-accent-4 text-start"> <b>Name</b> : {{ fileName }}
                  </v-card-subtitle>
                  <v-card-subtitle class="text-orange-accent-4 text-start"> <b>Size</b> : {{
                      utilAddNumberComma(fileSize)
                  }} Bytes
                  </v-card-subtitle>
                </v-card>
                <v-progress-linear :active="tProgress" rounded indeterminate color="yellow-darken-2"></v-progress-linear>
                <v-btn class="mt-1 mx-1" :disabled="fileSubmitBtnDisabled" variant="text" color="green-accent-4" @click="onClickButtonFileSubmit">
                  Submit
                </v-btn>
                <v-btn class="mt-1 mx-1" :disabled="tProgress" variant="text" color="indigo-lighten-2" @click="onClickButtonFileCancel">
                  Cancel
                </v-btn>
              </div>
            </div>

            <v-file-input id="fileUploaderID" ref="fileUploader" v-model="files" class="d-none"
              prepend-icon="mdi-paperclip">
            </v-file-input>
          </v-card>
        </v-col>
        <v-col :cols="colsCardMessage">
          <v-card @click="onClickCardMessage" class="mx-auto text-center pt-3" color="#F1F8E9" height="253"
            elevation="0" variant="flat">
            <div v-if="colsCardFile === 6">
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-img src="../assets/satoshibento_message.png" class="my-1" contain height="50" alt="Engrave your message in opreturn of the bitcoin network and notary" />
              <v-card-subtitle :class="colors.text00">SatoshiBento made of</v-card-subtitle>
              <v-card-subtitle :class="colors.text00"><b>Your Message</b></v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle :class="colors.text00">Engrave your message</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle :class="colors.text00">max-size: {{ limitMessageSize }} Bytes</v-card-subtitle>
              <v-card-subtitle :class="colors.text00">{{ orderPayValueMessage }} BTC</v-card-subtitle>
            </div>

            <div v-else-if="colsCardMessage === 2">
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-card-subtitle>&nbsp;</v-card-subtitle>
              <v-img src="../assets/satoshibento_message.png" class="my-1" contain height="30" alt="Engrave your message in opreturn of the bitcoin network and notary"/>
            </div>
            <div v-else-if="colsCardMessage === 10">
              <v-img src="../assets/satoshibento_message.png" class="my-1" contain height="30" alt="Engrave your message in opreturn of the bitcoin network and notary" />
              <v-card-subtitle :class="colors.text00">SatoshiBento made of <b>Your Message</b></v-card-subtitle>
              <v-textarea class="mt-2 mb-0 mx-4 text-orange text-start" rows="2" :rules="textAreaRules" :label="messageSizeLabel"
                variant="outlined" bg-color="white" color="orange" clearable clear-icon="mdi-close-circle"
                :model-value="messages" @update:modelValue="updateDataTextAreaMessage" counter
                :counter-value="(value) => messageDigestLabel(value)">
                <!-- @keydown.enter.prevent="noenter()" -->
              </v-textarea>
              <v-progress-linear :active="tProgress" rounded indeterminate color="yellow-darken-2"></v-progress-linear>
              <v-card-subtitle :class="colors.text00">Engrave your message of the bitcoin network</v-card-subtitle>
              <v-btn class="mx-1 my-1" :disabled="messageSubmitBtnDisabled" variant="text" color="green-accent-4" @click="onClickButtonMessageSubmit">
                Submit
              </v-btn>
              <v-btn class="mx-1 my-1" :disabled="tProgress" variant="text" color="indigo-lighten-2" @click="onClickButtonMessageCancel">
                Cancel
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import cryptojs from "crypto-js";
import { initializeApp } from "firebase/app";
import { collection, getFirestore, getDoc, getDocs, setDoc, updateDoc, deleteDoc, doc } from "firebase/firestore"; 
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { Buffer } from 'buffer';


const firebaseConfig = {
  apiKey: process.env.VUE_APP_SatoshiBentoShopApiKey,
  authDomain: process.env.VUE_APP_SatoshiBentoShopAuthDomain,
  databaseURL: process.env.VUE_APP_processSatoshiBentoShopDatabaseURL,
  projectId: process.env.VUE_APP_SatoshiBentoShopProjectId,
  storageBucket: process.env.VUE_APP_SatoshiBentoShopStorageBucket,
  messagingSenderId: process.env.VUE_APP_SatoshiBentoShopMessagingSenderId,
  appId: process.env.VUE_APP_SatoshiBentoShopAppId,
  measurementId: process.env.VUE_APP_SatoshiBentoShopMeasurementId,
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const fireStoreOrderIntegritys = process.env.VUE_APP_SatoshiBentoFireStoreOrderIntegritys;
const fireStoreOrderPendings = process.env.VUE_APP_SatoshiBentoFireStoreOrderPendings;
const fireStoreOrderDetails = process.env.VUE_APP_SatoshiBentoFireStoreOrderDetails;
const fireStoreOrderStandBys = process.env.VUE_APP_SatoshiBentoFireStoreOrderStandBys;
const cloudStorageParentPath = process.env.VUE_APP_SatoshiBentoCloudStorageParentPath

export default {

  setup() {
  },
  data: () => ({

    progress: false,
    orderPayValueFile: 0.0009,
    orderPayValueMessage: 0.0005,

    limitFileSizeMB: 4,
    limitMessageSize: 80,

    colors: {
      text00: "text-orange-accent-4",
      text01: "text-orange-lighten-3",
    },

    files: [],
    messages: "",
    orderDigest: "",

    activationMode: "none", // "none" , "file" , "message"

    colsCardFile: 6,
    colsCardMessage: 6,

    onClickBtnFile: "", // "", "select", "cancel", "submit"

    onClickBtnMessage: "", // "", "cancel", "submit"

    textAreaRules: [v => new Blob([v]).size <= 80 || 'Max 80 Bytes'],


  }),

  methods: {
    noenter(){},  
    messageDigestLabel(msg) {
      if (msg === "") {
        this.orderDigest = "";
        return "Digest: SHA256";
      } else {
        const tSizeBytesMessage = new Blob([msg]).size;
        if (tSizeBytesMessage <= 80) {
          const messageDigest = cryptojs.SHA256(msg).toString();
          this.orderDigest = messageDigest;
          return messageDigest;
        } else {
          this.orderDigest = "";
          return "";
        }
      }
    },

    updateDataTextAreaMessage(msg) {
      this.messages = msg;
    },

    utilAddNumberComma(num) {
      var regexp = /\B(?=(\d{3})+(?!\d))/g;
      if (num === undefined) {
        num = 0;
      }
      return num.toString().replace(regexp, ',');
    },

    utilCalFileDigest(valFiles) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        reader.onload = function () {
          var file_wordArr = cryptojs.lib.WordArray.create(reader.result);
          const digest = cryptojs.SHA256(file_wordArr).toString();
          resolve(digest);
        };
        reader.readAsArrayBuffer(valFiles[0]);
      });
    },

    updateDataOrderDigest: async function () {
      const nDigest = await this.utilCalFileDigest(this.files);
      this.orderDigest = nDigest;
    },

    onClickButtonFileSubmit() {
      this.onClickBtnFile = "submit";
    },

    onClickButtonFileSelect() {
      this.onClickBtnFile = "select";
    },

    onClickButtonFileCancel() {
      this.onClickBtnFile = "cancel";
    },

    onClickCardFile() {
      switch (this.activationMode) {
        case "file":
          if (this.onClickBtnFile === "cancel") {
            this.activationMode = "none";
            this.onClickBtnFile = "";
          } else if (this.onClickBtnFile === "select") {
            this.$refs.fileUploader.click();
            this.onClickBtnFile = "";
          } else if (this.onClickBtnFile === "submit") {
            this.onClickBtnFile = "";
            this.submitOrder();
          } else {
            this.onClickBtnFile = "";
          }
          break;
        case "message":
          this.activationMode = "none";
          break;
        case "none":
          this.activationMode = "file";
          break;
      }
    },

    onClickCardMessage() {
      switch (this.activationMode) {
        case "message":
          if (this.onClickBtnMessage === "cancel") {
            this.activationMode = "none";
            this.onClickBtnMessage = "";
          } else if (this.onClickBtnMessage === "submit") {
            this.onClickBtnFile = "";
            this.submitOrder();
          } else {
            this.onClickBtnFile = "";
          }
          break;
        case "file":
          this.activationMode = "none";
          break;
        case "none":
          this.activationMode = "message";
          break;
      }
    },
    onClickButtonMessageSubmit() {
      this.onClickBtnMessage = "submit";
    },
    onClickButtonMessageCancel() {
      this.onClickBtnMessage = "cancel";
    },




    submitOrder: async function () {

      this.progress = true;
      const limitTS01 = 1000 * 60 //  * 60 * 24 * 1; // 1-Day
      // const limitTS05 = 1000 * 60 * 60 * 24 * 5; // 5-Day for expire

      const orderTS = Date.now();
      const orderType = this.activationMode.toUpperCase(); // "FILE" , "MESSAGE"
      const orderHash = this.orderDigest;
      let orderSize = 0;
      let orderValue = "";
      let orderHex = "";
      let payValue = 1.0; // BTC
      let orderID = "";

      // 01
      // Validation 
      // File or Message
      if (orderType === "MESSAGE") {
        orderSize = this.messageSize;
        orderValue = this.messages;
        payValue = this.orderPayValueMessage;
        // // TODO 
        console.log("HEX0: message1");
        orderHex = Buffer.from(this.messages).toString('hex');
        console.log("HEX1: "+ orderHex)
      } else if (orderType === "FILE") {
        orderSize = this.fileSize;
        orderValue = "";
        payValue = this.orderPayValueFile;
      } else {
        this.progress = false;
        return;
      }


      console.log("== submit orderHash :", orderHash);
      
      // 02 
      // Validation 
      // : FireStore _ OrderIntegritys-Digest 
      //   : if exist 
      //     : data reset 
      //     : redirect - satoshibento.io/order/{orderid}
      const docRefIntegrity = doc(db, fireStoreOrderIntegritys, orderHash);
      const docSnapshotIntegrity = await getDoc(docRefIntegrity);
      if (docSnapshotIntegrity.exists()) {
        const dataIntegrity = docSnapshotIntegrity.data();
        const digestOrderID = dataIntegrity.OrderID;
        const digestTS = dataIntegrity.TS;
        if ((orderTS - digestTS) > limitTS01){
          const docRefPending = doc(db, fireStoreOrderPendings, digestOrderID);
          const docSnapshotPending = await getDoc(docRefPending);
          if (docSnapshotPending.exists()){ 
            const docRefDetail = doc(db, fireStoreOrderDetails, digestOrderID);
            await updateDoc(docRefDetail, {"PendingTS":orderTS})
            await updateDoc(docRefIntegrity, {"TS":orderTS})
            await updateDoc(docRefPending, {"TS":orderTS})
          }
        }
        this.$router.push("/order/" + digestOrderID);
        this.progress = false;
        return
      }
      
      // 03
      // Get List of New OrderIDs 
      const standBys = await getDocs(collection(db, fireStoreOrderStandBys))
      if (standBys.empty) {
        this.progress = false;
        alert("Empty StandBys");
        return;
      }
      
      // 04.
      // Select New OrderID  => orderID
      const randomIndex = Math.floor(Math.random() * standBys.size);
      let index = 0
      standBys.forEach(doc => {
        if (index === randomIndex) {
          orderID = doc.id
        }
        index = index + 1;
      });
      
      console.log("== submit:", orderTS , orderType, orderHash, orderSize, orderValue, payValue, orderID)

      // 05.
      // Delete OrderStandBys
      await deleteDoc(doc(db, fireStoreOrderStandBys, orderID));

      // 06. 
      // Create OrderIntegritys
      let tItem = {
        OrderID: orderID,
        SHA256: orderHash,
        Type: orderType.toLowerCase(),
        TS: orderTS,
      };
      await setDoc(doc(db, fireStoreOrderIntegritys, orderHash), tItem);


      // 07. 
      // Create OrderPendings
      tItem = {
        OrderID: orderID,
        SHA256: orderHash,
        Type: orderType.toLowerCase(),
        TS: orderTS,
      };
      await setDoc(doc(db, fireStoreOrderPendings, orderID), tItem);


      // 08. 
      // Create OrderDetails
      tItem = {
        OrderID: orderID,
        OrderTS: orderTS,
        PendingTS: orderTS,
        OrderPayValue: payValue,
        ContentType: orderType.toLowerCase(),
        ContentSHA256: orderHash,
        ContentSize: orderSize,
        OrderStatus: "Pending",
      };
      switch (orderType.toUpperCase()) {
        case "MESSAGE":
          tItem["ContentMessage"] = orderValue;
          tItem["ContentHex"] = orderHex;
          break;
        case "FILE":
          break;
      }
      await setDoc(doc(db, fireStoreOrderDetails, orderID), tItem);

      // 09. 
      // Upload File 
      if (orderType.toUpperCase() === "FILE"){
        const storage = getStorage();
        
        const uploadPath = `${cloudStorageParentPath}/${orderID}/upload/${this.files[0].name}`;
        const storageRef = ref(storage, uploadPath);
        await uploadBytes(storageRef, this.files[0]) // .then((snapshot) => {console.log('Uploaded a blob or file!', snapshot);});
      }
      this.progress = false;

      // 10.
      // Redirect - satoshibento.io/order/{orderid}
      this.$router.push("/order/" + orderID);
    },
  },

  computed: {

    tProgress(){
      return this.progress;
    },

    messageSize() {
      return new Blob([this.messages]).size ;
    },
    messageSizeLabel() {
      const tBytes = new Blob([this.messages]).size;
      if (tBytes === 0) {
        return "Max 80-Bytes";
      } else {
        return tBytes + " Bytes";
      }
    },
    messageSubmitBtnDisabled() {
      if ((this.sizeBytesMessage > 80) || (this.sizeBytesMessage <= 0) ) {
        return true;
      } else {
        return false;
      }
    },

    fileSubmitBtnDisabled() {
      if (this.fileCount <= 0) {
        return true;
      } else if(this.files[0].size > this.limitFileSizeMB * 1024 * 1024) {
        // const mb = Math.round((this.files[0].size / 1024.0 / 1024.0) * 100) / 100
        alert("YourFileSize: "+this.utilAddNumberComma(this.files[0].size) +" bytes\nBut MaxSize: " + this.utilAddNumberComma(this.limitFileSizeMB * 1024 * 1024)+ " bytes")
        return true;
      }else{
        return false;
      }
    },
    fileCount() {
      return this.files.length;
    },
    fileSize() {
      if (this.fileCount <= 0) {
        return 0;
      }
      return this.files[0].size;
    },
    fileName() {
      if (this.fileCount <= 0) {
        return "";
      }
      return this.files[0].name;
    },
  },
  watch: {
    files(fileVal) {
      if (fileVal.length > 0) {
        this.updateDataOrderDigest();
      } else {
        this.orderDigest = "";
      }
    },
    activationMode(tMode) {
      document.getElementById('fileUploaderID').value = "";
      this.files = [];
      this.messages = "";
      this.orderDigest = "";
      switch (tMode) {
        case "file":
          console.log("== activationMode : file")
          this.colsCardFile = 10;
          this.colsCardMessage = 2;
          break;
        case "message":
          console.log("== activationMode : message")
          this.colsCardFile = 2;
          this.colsCardMessage = 10;
          break;
        case "none":
          console.log("== activationMode : none")
          this.colsCardFile = 6;
          this.colsCardMessage = 6;
          break;
      }
    },
  },
};
</script>
