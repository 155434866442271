import { createWebHistory, createRouter } from "vue-router";
import SatoshiBento_Home from "../views/Home.vue";
import SatoshiBento_Order from "../views/Order.vue";
import SatoshiBento_SatoshiPen_For_User from "../views/SatoshiPenForUser";
import SatoshiBento_SatoshiBook_For_User from "../views/SatoshiBookForUser";

const routes = [
  
  {
    path: "/",
    name: "SatoshiBento_Home",
    component: SatoshiBento_Home,
  },

  {
    path: "/satoshipen/foruser",
    name: "SatoshiPen_For_User",
    component: SatoshiBento_SatoshiPen_For_User, 
  },
  {
    path: "/satoshibook/foruser",
    name: "SatoshiBook_For_User",
    component: SatoshiBento_SatoshiBook_For_User, 
  },
  
  {
    path: "/order/:orderid",
    name: "SatoshiBento_Order",
    component: SatoshiBento_Order, 
  },
  
  {
    path: "/order",
    redirect: "/",
  },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      const tTo  = to.name;
      const tFrom = from.name;
      if (tTo === undefined || tFrom === undefined){
        return { top:0 ,behavior: 'smooth'}
      }
      if ((tTo === "SatoshiBento_Home") && (tFrom === "SatoshiBento_Order")){
        return { top: 700 ,behavior: 'smooth'}
      }
      if (savedPosition) {
        return { top: 0,behavior: 'smooth'}
      } else {
        return { top: 0 ,behavior: 'smooth'}
      }
    },
  });