
<template>
    <sb_satoshibook_for_user />
</template> 

<script>
import sb_satoshibook_for_user from "../components/SB_SatoshiBook_For_User.vue";

export default {
    name: "SatoshiBook_For_User",
    components: {
        sb_satoshibook_for_user,
    },
    setup() {},
};
</script>
