<template>

    <!-- <sb_search /> -->
    <sb_details />

</template> 

<script>
// import sb_search from "../components/SB_Search.vue";
import sb_details from "../components/SB_Details.vue";
import { useStore } from "vuex";
import { useRoute , useRouter} from "vue-router";


export default {
    name: "SatoshiBento_Order",
    components: {
        // sb_search,
        sb_details,
    },


    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        store.commit("setOrderID", route.params.orderid);
        router.push({name:'SatoshiBento_Home'});
    },
};
</script>
